@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Public+Sans:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
    font-family: 'satoshi_lighter';
    src: url('./assets/Fonts/WEB/fonts/Satoshi-Light.woff2') format('woff2'), url('./assets/Fonts/WEB/fonts/Satoshi-Light.woff') format('woff'),
        url('./assets/Fonts/WEB/fonts/Satoshi-Light.ttf') format('truetype');
    font-weight: lighter;
}

@font-face {
    font-family: 'satoshi_normal';
    src: url('./assets/Fonts/WEB/fonts/Satoshi-Regular.woff2') format('woff2'), url('./assets/Fonts/WEB/fonts/Satoshi-Regular.woff') format('woff'),
        url('./assets/Fonts/WEB/fonts/Satoshi-Regular.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'satoshi_medium';
    src: url('./assets/Fonts/WEB/fonts/Satoshi-Medium.woff2') format('woff2'), url('./assets/Fonts/WEB/fonts/Satoshi-Medium.woff') format('woff'),
        url('./assets/Fonts/WEB/fonts/Satoshi-Medium.ttf') format('truetype');
    font-weight: medium;
}

@font-face {
    font-family: 'satoshi_bold';
    src: url('./assets/Fonts/WEB/fonts/Satoshi-Bold.woff2') format('woff2'), url('./assets/Fonts/WEB/fonts/Satoshi-Bold.woff') format('woff'),
        url('./assets/Fonts/WEB/fonts/Satoshi-Bold.ttf') format('truetype');
    font-weight: bold;
}

.heading_1 {
    color: var(--Text-primary, #212121);
    font-family: 'satoshi_bold';
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

.heading_2 {
    color: #141414;
    font-family: 'satoshi_bold';
    font-size: 2.25rem;
    font-style: normal;
    line-height: 120%;
}

.heading_3 {
    color: #0c0c0c;
    font-family: 'Lexend' sans-serif;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

.heading_4 {
    color: var(--CoolGray-100, #121619);
    font-family: 'satoshi_bold';
    font-size: 1.5rem;
    font-style: normal;
    line-height: 135%;
}

.heading_5 {
    color: #000;
    font-family: 'satoshi_medium';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.0125rem;
}

.heading_6 {
    color: #141414;
    font-family: 'satoshi_bold';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 1.5rem */
    letter-spacing: 0.0125rem;
}

.paragraph_1 {
    color: var(--Text-primary, #212121);
    font-family: 'DM Sans';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.paragraph_2 {
    color: #626364;
    font-family: 'DM Sans';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.paragraph_3 {
    color: #141414;
    font-family: 'DM Sans';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}

.label_1 {
    color: #141414;
    font-family: 'satoshi_bold';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
}

.label_2 {
    color: var(--Ndovu-Black, #121212);
    font-family: 'satoshi_bold';
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
}

.label_3 {
    color: #333;
    font-family: 'satoshi_medium';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.label_4 {
    color: var(--Ndovu-Black, #121212);
    font-family: 'satoshi_medium';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
}

.regular_12 {
    color: #989797;
    text-align: right;
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
    font-family: 'Inter';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
}

.semi_bold_14 {
    color: var(--black-100, #1c1c1c);
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
    font-family: 'Inter';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
}

.semi_bold_24 {
    color: var(--black-100, #1c1c1c);
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
    font-family: 'Inter';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.25rem;
}

.title_medium_m3 {
    color: var(--Schemes-On-Surface, #1d1b20);
    font-family: var(--Title-Medium-Font, Roboto);
    font-size: var(--Title-Medium-Size, 1rem);
    font-style: normal;
    font-weight: 500;
    line-height: var(--Title-Medium-Line-Height, 1.5rem);
    letter-spacing: var(--Title-Medium-Tracking, 0.00938rem);
}

.profile_email {
    color: var(--Black, #000);
    font-family: 'DM Sans';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chama_card_date {
    color: #141414;
    font-family: 'DM Sans';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.activity_text {
    color: var(--Text-primary, #212121);
    font-family: 'DM Sans';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.invalid span {
    color: var(--Text-secondary, #616161);
    font-family: 'DM Sans';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}

.select_title {
    color: var(--Gray-700, #344054);
    font-family: 'satoshi_medium';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    /* 142.857% */
}

.toolTip_header {
    color: var(--Ndovu-Black, #121212);
    font-family: 'satoshi_bold';
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: -0.011rem;
}

.toolTip_text {
    color: var(--Neutral-90, #404040);
    font-family: 'satoshi_medium';
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.role_header {
    color: var(--Gray-900, #101828);
    font-family: 'DM Sans';
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
}

.role_text {
    color: var(--Gray-500, #667085);
    font-family: 'DM Sans';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}

.role_btn_text {
    color: var(--Black, #000);
    font-family: 'DM Sans';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}

.send_invite_text {
    color: #1d191b;
    font-family: 'satoshi_medium';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
}

.settlement_empty_text {
    color: var(--light-type-medium, #64748b);
    font-family: 'DM Sans';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}

.activity_card_header {
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
    font-family: 'satoshi_bold';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem;
}

.activity_card_text {
    color: #fff;
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv11' on;
    font-family: 'Inter';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.25rem;
}

.view_more_text {
    color: var(--Ndovu-Black, #121212);
    font-family: 'satoshi_normal';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
}

.modal_success_header {
    color: var(--Neutral-100, #0a0a0a);
    font-family: 'Public Sans';
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    letter-spacing: -0.011rem;
}

.modal_success_text {
    color: var(--Neutral-90, #404040);
    font-family: 'Open Sans';
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.graph_icon_text {
    color: #0d163a;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Plus Jakarta Sans';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.member_info_text {
    color: var(--Black-Default, #000);
    font-family: 'Public Sans';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 1.225rem */
}

.landing_logo_txt {
    color: var(--CoolGray-100, #121619);
    font-family: 'satoshi_bold';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 1.5rem */
    letter-spacing: 0.0125rem;
}

.landing_menu_item {
    color: #141711;
    font-family: 'satoshi_bold';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.6875rem;
    /* 150% */
}

.landing_menu_item_login {
    color: #454643;
    font-family: 'satoshi_medium';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem;
    /* 187.5% */
}

.landing_banner_header {
    color: #141711;
    text-align: center;
    font-family: 'satoshi_medium';
    font-size: 3.78419rem;
    font-style: normal;
    font-weight: 500;
    line-height: 4.2625rem;
    /* 112.64% */
}

.landing_banner_sub_header {
    color: #141711;
    text-align: center;
    font-family: 'satoshi_bold';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.875rem;
    /* 150% */
}

.landing_features_header {
    color: #141711;
    font-family: 'satoshi_medium';
    font-size: 3.42481rem;
    font-style: normal;
    font-weight: 500;
    line-height: 3.85rem;
    /* 112.415% */
}

.landing_features_card_header {
    color: #141711;
    font-family: 'satoshi_medium';
    font-size: 1.46488rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.95rem;
    /* 133.12% */
}

.landing_features_card_txt {
    color: #454643;
    font-family: 'satoshi_medium';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem;
    /* 150% */
}

.landing_get_started {
    color: #141711;
    font-family: 'satoshi_medium';
    font-size: 1.44144rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.65rem;
    /* 114.472% */
}

.landing_get_quick_easy {
    color: #141711;
    font-family: 'satoshi_medium';
    font-size: 3.418rem;
    font-style: normal;
    font-weight: 500;
    line-height: 3.85rem;
    /* 112.64% */
}

.landing_get_started_list_item {
    color: #141711;
    font-family: 'satoshi_medium';
    font-size: 1.44144rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.65rem; /* 114.472% */
}

.landing_get_started_list_item_desc {
    color: #141711;
    font-family: 'satoshi_medium';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem; /* 150% */
}

.landing_insight_header {
    color: #141711;
    text-align: center;
    font-family: 'satoshi_medium';
    font-size: 3.40431rem;
    font-style: normal;
    font-weight: 500;
    line-height: 3.85rem; /* 113.092% */
}

.landing_insight_sub_header {
    color: #141711;
    font-family: 'satoshi_medium';
    font-size: 1.46488rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.65rem; /* 112.64% */
}

.landing_insight_text {
    color: #454643;
    font-family: 'satoshi_medium';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem; /* 150% */
}

.landing_insight_pro_tip {
    color: #141711;
    font-family: 'satoshi_medium';
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.8875rem; /* 165% */
}

.landing_stress_free {
    color: #141711;
    text-align: center;
    font-family: 'satoshi_bold';
    font-size: 1.98438rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.66rem; /* 134.047% */
}

.landing_stress_txt {
    color: #141711;
    text-align: center;
    font-family: 'Work Sans';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem; /* 140% */
}

.lanidng_about_header {
    color: #141711;
    font-family: 'satoshi_medium';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.95rem; /* 130% */
}

.lanidng_about_txt {
    color: #454643;
    font-family: 'satoshi_medium';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem; /* 150% */
}

.lanidng_reach_out_header {
    color: #141711;
    text-align: center;
    font-family: 'satoshi_medium';
    font-size: 3.72363rem;
    font-style: normal;
    font-weight: 500;
    line-height: 4.2625rem; /* 114.472% */
}

.lanidng_reach_out_txt {
    color: #141711;
    text-align: center;
    font-family: 'satoshi_medium';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem; /* 150% */
}

.lanidng_reach_out_card_header {
    color: #141711;
    text-align: center;
    font-family: 'satoshi_medium';
    font-size: 1.47656rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.65rem; /* 111.746% */
}

.lanidng_reach_out_card_txt {
    color: #4d4d4d;
    text-align: center;
    font-family: 'satoshi_medium';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem; /* 150% */
}

.lanidng_faq_header {
    color: #141711;
    text-align: center;
    font-family: 'satoshi_medium';
    font-size: 3.459rem;
    font-style: normal;
    font-weight: 500;
    line-height: 3.85rem; /* 111.304% */
}

.lanidng_faq_faq_header {
    color: #141711;
    font-family: 'satoshi_medium';
    font-size: 1.44144rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.95rem; /* 135.285% */
}

.lanidng_faq_faq_txt {
    color: #454643;
    font-family: 'satoshi_medium';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem; /* 150% */
}

.landing_footer_header {
    color: #fff;
    font-family: 'satoshi_medium';
    font-size: 3.73119rem;
    font-style: normal;
    font-weight: 500;
    line-height: 5.0375rem; /* 135.01% */
}

.landing_footer_follow_us_txt {
    color: #fff;
    font-family: 'satoshi_medium';
    font-size: 1.46488rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.65rem; /* 112.64% */
}

.landing_footer_item_header {
    color: #fff;
    font-family: 'satoshi_medium';
    font-size: 1.45313rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.95rem; /* 134.194% */
}

.landing_footer_item_txt {
    text-align: left;
    color: #a6a8a4;
    font-family: 'satoshi_medium';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem; /* 150% */
}

.landing_footer_copywrite {
    color: #fff;
    font-family: 'satoshi_medium';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem; /* 187.5% */
}

.filter_header {
    color: var(--light-type-high, #070a13);
    font-family: 'Public Sans';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2rem; /* 133.333% */
}

.filter_sub_header {
    color: #10101e !important;
    font-family: 'Public Sans';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
}

.filter_date_text {
    color: var(--light-type-medium, #64748b);
    font-family: 'Public Sans';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
}
