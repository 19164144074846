@font-face {
    font-family: 'satoshi_bold';
    src: url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff2") format('woff2'),
        url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff") format('woff'),
        url("./assets/Fonts/WEB/fonts/Satoshi-Bold.ttf") format('truetype');
    font-weight: bold;
}

.month-selector {
    width: 21.5rem;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
}

.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header h3 {
    color: var(--light-type-high, #070A13);
    text-align: center;

    font-family: "satoshi_bold";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
}

.back-btn {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
}

.month-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin: 20px 0;
}

.month-btn {
    padding: 10px;
}

.month-btn.selected {
    border: 1px solid #121212;
}

.footer {
    display: flex;
    justify-content: space-between;
}

.cancel-btn,
.next-btn {
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.cancel-btn {
    background-color: #f5f5f5;
}

.next-btn {
    background-color: #ffe4b3;
    border-color: #f0ad4e;
}

.next-btn:disabled {
    background-color: #f5f5f5;
    border-color: #ccc;
    cursor: not-allowed;
}