@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
    font-family: 'satoshi_lighter';
    src: url('./assets/Fonts/WEB/fonts/Satoshi-Light.woff2') format('woff2'), url('./assets/Fonts/WEB/fonts/Satoshi-Light.woff') format('woff'),
        url('./assets/Fonts/WEB/fonts/Satoshi-Light.ttf') format('truetype');
    font-weight: lighter;
}

@font-face {
    font-family: 'satoshi_normal';
    src: url('./assets/Fonts/WEB/fonts/Satoshi-Regular.woff2') format('woff2'), url('./assets/Fonts/WEB/fonts/Satoshi-Regular.woff') format('woff'),
        url('./assets/Fonts/WEB/fonts/Satoshi-Regular.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'satoshi_medium';
    src: url('./assets/Fonts/WEB/fonts/Satoshi-Medium.woff2') format('woff2'), url('./assets/Fonts/WEB/fonts/Satoshi-Medium.woff') format('woff'),
        url('./assets/Fonts/WEB/fonts/Satoshi-Medium.ttf') format('truetype');
    font-weight: medium;
}

@font-face {
    font-family: 'satoshi_bold';
    src: url('./assets/Fonts/WEB/fonts/Satoshi-Bold.woff2') format('woff2'), url('./assets/Fonts/WEB/fonts/Satoshi-Bold.woff') format('woff'),
        url('./assets/Fonts/WEB/fonts/Satoshi-Bold.ttf') format('truetype');
    font-weight: bold;
}

/* ANTD FORM ITEM */

.ant-form-item {
    width: 100% !important;
    margin-bottom: 1.25rem !important;
}

.table-form-item {
    margin-bottom: 0 !important;
}

.margin_less_form_item {
    width: 100% !important;
    margin-bottom: 0rem !important;
}

.ant-form-item-label {
    padding: 0 0 0.25rem !important;
}

.ant-form-item-label label {
    color: var(--Text-Primary, #212121) !important;
    font-family: 'satoshi_medium' !important;
    font-size: 1rem !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 1.5rem !important;
}

.ant-form-item-extra {
    color: var(--Text-secondary, #616161) !important;
    font-family: 'DM Sans' !important;
    font-size: 0.875rem !important;
    font-style: italic !important;
    font-weight: 400 !important;
    line-height: 1.25rem !important;
    text-align: left !important;
}

.ant-form-item-required::before {
    content: '' !important;
}

.ant-form-item-required::after {
    content: '*' !important;
    visibility: visible !important;
}

.ant-form-item-explain-error {
    text-align: left !important;
}

/* ANTD SELECT */

.ant-select-multiple .ant-select-selection-item {
    height: 39px !important;
    margin-top: 0px !important;
}

.ant-select-selector {
    height: 3.125rem !important;
    width: 100% !important;
    align-items: center !important;
    border-radius: 0.375rem !important;
    border: 1px solid #212121 !important;
    box-shadow: none !important;
}

.ant-select {
    height: 3.125rem !important;
    width: 100% !important;
    align-items: center !important;
    border-radius: 0.375rem !important;
    box-shadow: none !important;
}

.select-borderless .ant-select-selector {
    height: 2.125rem !important;
    width: 100% !important;
    align-items: center !important;
    border-radius: 0.375rem !important;
    border: 0px !important;
    box-shadow: none !important;
    background: transparent !important;
    padding: 0px !important;
}

.select-borderless {
    height: 2.125rem !important;
    width: 100% !important;
    align-items: center !important;
    border-radius: 0.375rem !important;
    border: 0px !important;
    background: transparent !important;
}

.graph_select {
    height: 2.125rem !important;
    width: 100% !important;
    align-items: center !important;
    border-radius: 0.5rem !important;
}

.graph_select .ant-select-selector {
    height: 2.125rem !important;
    width: 100% !important;
    align-items: center !important;
    border-radius: 0.5rem !important;
    border: 1px solid #dedede !important;
    box-shadow: none !important;
}

.ant-select-disabled .ant-select-selection-item {
    color: #101828 !important;
}

.ant-select .ant-select-arrow {
    color: #212121 !important;
    font-size: 16px !important;
}

.ant-select-selection-item {
    display: flex !important;
    align-items: center !important;
}

/* ANTD INPUT */

.ant-input-affix-wrapper {
    border-radius: 50px;
    width: 293px;
    height: 50px;
    border: 1px solid #ececec;
    font-size: 18px;
    background: #fff !important;
}

.ant-input-affix-wrapper:focus {
    border: 1px solid rgba(56, 142, 60, 0.28) !important;
}

.ant-input-affix-wrapper-focused {
    border: 1px solid rgba(56, 142, 60, 0.28) !important;
}

.ant-input-affix-wrapper:hover {
    border: 1px solid rgba(56, 142, 60, 0.28) !important;
}

.ant-input-number-input-wrap {
    height: 100% !important;
}

.ant-input-number-input {
    height: 100% !important;
    padding: 0.25rem !important;
}

.borderless-input .ant-input-affix-wrapper:focus {
    border: 0px !important;
}

.borderless-input .ant-input-affix-wrapper-focused {
    border: 0px !important;
}

.borderless-input .ant-input-affix-wrapper:hover {
    border: 0px !important;
}

.ant-input-number-handler-wrap {
    display: none !important;
}

.ant-input-data-count {
    bottom: -30px !important;
}

/* ANTD TABS */

.ant-tabs-nav-wrap {
    width: 100% !important;
    padding: 0px 0px !important;
    background-color: inherit !important;
}

.ant-tabs-nav-list {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.ant-tabs-ink-bar {
    display: none !important;
}

.ant-tabs-tab-active {
    display: flex !important;
    padding: 1rem 0.75rem !important;
    align-items: flex-start !important;
    gap: 0.5rem !important;
    border: 1px solid var(--Border-secondary, #ececec) !important;
    background: #fff !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--colors-brand-primary-alt-500, #000) !important;
    font-family: 'DM Sans' !important;
    font-size: 1rem !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 1.5rem !important;
    /* 150% */
}

.ant-tabs-tab:hover > .ant-tabs-tab-btn {
    color: var(--colors-brand-primary-alt-500, #000) !important;
}

.ant-tabs-tab:focus > .ant-tabs-tab-btn {
    color: var(--colors-brand-primary-alt-500, #000) !important;
}

.ant-tabs-top > .ant-tabs-nav::before {
    border: none !important;
}

.underlined_tab .ant-tabs-tab-active {
    display: flex !important;
    padding: 0 !important;
    /* padding-bottom: 1.12rem !important; */
    align-items: flex-start !important;
    gap: 0.5rem !important;
    border: none !important;
    border-bottom: 0.1875rem solid #000 !important;
    background: transparent !important;
}

.underlined_tab .ant-tabs-tab {
    padding: 0px !important;
    width: 100% !important;
}

.underlined_tab .ant-tabs-tab-btn {
    width: 100% !important;
    text-align: center !important;
    color: var(--Text-Black, #070a13) !important;
    font-family: 'Lexend' !important;
    font-size: 1rem !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 1.5rem !important;
    /* 150% */
}

.underlined_tab_small .ant-tabs-tab-active {
    display: flex !important;
    padding: 0 !important;
    align-items: flex-start !important;
    gap: 0.5rem !important;
    border: none !important;
    border-bottom: 0.1875rem solid #000 !important;
    background: transparent !important;
}

.underlined_tab_small .ant-tabs-tab {
    padding: 0px !important;
    width: 100% !important;
}

.underlined_tab_small .ant-tabs-tab-btn {
    width: 100% !important;
    text-align: center !important;
    color: var(--Text-Black, #070a13) !important;
    font-family: 'Lexend' !important;
    font-size: 1rem !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 1.5rem !important;
    /* 150% */
}

.underlined_tab_small .ant-tabs-nav-list {
    width: fit-content !important;
    display: flex !important;
}

.tab_orange .ant-tabs-tab-active {
    border-bottom: 0.1875rem solid #ffb600 !important;
}

.landing_tab .ant-tabs-tab {
    padding: 1.25rem 0rem 1.25rem 1.9375rem !important;
    border-left: 1px solid #d9dad7 !important;
    margin: 0px 0 0 0 !important;
}

.landing_tab .ant-tabs-tab-active {
    background: none !important;
    border: none !important;
    padding: 1.25rem 0rem 1.25rem 1.9375rem !important;
    border-left: 1px solid #141711 !important;
}

.landing_tab .ant-tabs-content-holder {
    border: none !important;
}

.landing_tab .ant-tabs-tab .ant-tabs-tab-btn {
    color: #a6a8a4 !important;
    font-family: 'satoshi_medium' !important;
    font-size: 1.83106rem !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 2.8125rem !important;
}

.landing_tab .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #141711 !important;
    font-family: 'satoshi_medium' !important;
    font-size: 1.82375rem !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 2.8125rem !important;
}

.landing_tab .ant-tabs-nav-list {
    flex: none !important;
}

/* ANTD BREADCRUMB */

.ant-breadcrumb-link {
    color: var(--Ndovu-Black, #121212) !important;
    font-family: 'DM Sans' !important;
    font-size: 0.875rem !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 1.25rem !important;
}

a.ant-breadcrumb-link {
    color: var(--Text-secondary, #616161) !important;
    font-family: 'DM Sans' !important;
    font-size: 0.875rem !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 1.25rem !important;
}

/* ANTD TABLE */

.ant-table-wrapper {
    border-radius: 0rem !important;
    background: transparent !important;
    box-shadow: 0px !important;
    border-left: 1px solid rgba(224, 224, 224, 0) !important;
}

.ant-table-thead .ant-table-cell {
    background: #f2f4f7 !important;
    color: var(--text-black, #222) !important;
    font-family: 'DM Sans' !important;
    font-size: 1rem !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    text-transform: capitalize !important;
    border-start-end-radius: 0px !important;
    border-start-start-radius: 0px !important;
}

.ant-table-cell {
    color: var(--Gray-900, #101828) !important;
    font-family: 'DM Sans' !important;
    font-size: 1rem !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 1.5rem !important;
}

.ant-table-footer {
    padding: 0px !important;
    background: transparent !important;
}

.editable_table .ant-table-cell {
    padding: 0.75rem !important;
}

/* ANTD SPIN */

.ant-spin-dot-item {
    background-color: #fff !important;
}

.blu_spin .ant-spin-dot-item {
    background-color: #1677ff !important;
}

/* ANTD MODAL */

.ant-modal-header {
    display: none !important;
}

.ant-modal-close-x {
    display: none !important;
}

.ant-modal-content {
    width: 41.5625rem !important;
    /* height: 37.9625rem !important; */
    display: inline-flex !important;
    flex-direction: column !important;
    align-items: center !important;
    border-radius: 0.875rem !important;
    background: #fff !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
    padding: 0px !important;
}

.pay-now-modal {
    width: 65rem !important;
}

.pay-now-modal .ant-modal-content {
    width: 65rem !important;
}

@media (max-width: 1024px) {
    .ant-modal-content {
        width: 100% !important;
    }

    .pay-now-modal {
        width: 100% !important;
    }

    .pay-now-modal .ant-modal-content {
        width: 100% !important;
    }
}

.ant-modal-body {
    width: 100% !important;
    padding-bottom: 2.5rem !important;
}

/* ANTD SWITCH */

.ant-switch {
    width: 3rem !important;
    height: 1.6rem !important;
    border: 2px solid #fff !important;
}

.ant-switch-checked {
    background: #fff !important;
    border: 4px solid #388e3c !important;
}

.ant-switch-checked > .ant-switch-handle::before {
    background-color: #388e3c !important;
}

.ant-switch-checked .ant-switch-handle {
    top: 0.2px !important;
}

/* ANTD DROPDOWN */

.ant-dropdown-menu-item {
    display: flex !important;
    min-height: 2.25rem !important;
    height: 100% !important;
    padding: 0.5rem !important;
    align-items: center !important;
    gap: 0.5rem !important;
    align-self: stretch !important;
    color: var(--text-text-drop-down, #404040) !important;
    font-variant-numeric: lining-nums tabular-nums !important;
    font-family: 'satoshi_medium' !important;
    font-size: 0.875rem !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 1.5rem !important;
}

.ant-dropdown-menu .ant-dropdown-menu-item:hover {
    background-color: #fff !important;
}

.avatar_dropdown .ant-dropdown-menu-item .ant-dropdown-menu-item-active {
    background-color: transparent !important;
}

.filter_dropdown .ant-dropdown-menu-item .ant-dropdown-menu-item-active {
    background-color: transparent !important;
}

.filter_dropdown {
    min-width: 17.25rem;
    width: auto;
    padding: 0.75rem;
}

.ant-dropdown-trigger {
    width: 100% !important;
    display: flex !important;
}

/* ANTD MENU */

.ant-menu {
    background: transparent !important;
    row-gap: 0rem !important;
    border-inline-end: 0px !important;
    padding-bottom: 5rem !important;
}

.ant-menu-item-icon {
    font-size: 1.25rem !important;
}

.ant-menu-submenu-selected > .ant-menu-submenu-title {
    height: 2.8125rem !important;
    background-color: #1d191b !important;
    padding: 0.75rem 1rem !important;
    gap: 0.75rem !important;
    height: 2.8125rem !important;
    border-radius: 0px !important;
    color: #fff !important;
    font-family: 'satoshi_medium' !important;
    font-size: 0.875rem !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 1.3125rem !important;
    letter-spacing: -0.00525rem !important;
}

.ant-menu-sub {
    padding: 0.5rem 0.75rem !important;
}

.ant-menu-item {
    display: flex !important;
    padding: 0.25rem 0.75rem !important;
    align-items: center !important;
    gap: 0.75rem !important;
    align-self: stretch !important;
    color: var(--Neutral-100, #0a0a0a) !important;
    font-family: 'satoshi_medium' !important;
    font-size: 0.875rem !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 150% !important;
    margin-block: 0px !important;
}

.ant-menu-item-selected {
    height: 1.8125rem !important;
    display: flex !important;
    padding: 0.25rem 0.75rem !important;
    align-items: center !important;
    gap: 0.75rem !important;
    align-self: stretch !important;
    border-radius: 0.375rem !important;
    background: var(--Light-Gold, #ffcd47) !important;
    color: var(--Ndovu-Black, #121212) !important;
    font-family: 'satoshi_bold' !important;
    font-size: 0.875rem !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 150% !important;
    letter-spacing: 0.00875rem !important;
}

.ant-menu-submenu > .ant-menu-submenu-title {
    display: flex !important;
    padding: 0.25rem 0.75rem !important;
    align-items: center !important;
    gap: 0.75rem !important;
    align-self: stretch !important;
}

li .ant-menu-submenu-open > .ant-menu-submenu-title {
    height: 1.8125rem !important;
    display: flex !important;
    padding: 0.25rem 0.75rem !important;
    align-items: center !important;
    gap: 0.75rem !important;
    align-self: stretch !important;
    border-radius: 0.375rem !important;
    background: var(--Light-Gold, #ffcd47) !important;
    color: var(--Ndovu-Black, #121212) !important;
    font-family: 'satoshi_bold' !important;
    font-size: 0.875rem !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 150% !important;
    letter-spacing: 0.00875rem !important;
}

/* ANTD BADGE */

.ant-badge-count {
    background: #c72c3b !important;
}

/* ANTD AVATAR */

.avatar_wrapper {
    width: auto !important;
}

.avatar_wrapper .ant-upload-list-item-container {
    width: 4.6875rem !important;
    height: 4.6875rem !important;
    margin-block: 0px !important;
    margin-inline: 0px !important;
}

.avatar_wrapper .ant-upload-list {
    display: flex !important;
    align-items: flex-end !important;
}

.avatar_wrapper .ant-upload-list-item {
    padding: 0px !important;
    border: 1px solid #3f51f4 !important;
}

.avatar_wrapper .ant-upload {
    width: auto !important;
    height: auto !important;
    margin-inline-end: 0px !important;
    margin-bottom: 0px !important;
    background-color: transparent !important;
    border: 0px !important;
    border-radius: 0px !important;
    cursor: pointer;
}

.avatar_wrapper .ant-upload-list-item-actions {
    display: none !important;
}

/* ANTD CHECKBOX */

.ant-checkbox-inner {
    width: 1.5rem !important;
    height: 1.5rem !important;
    border: 1px solid #94a3b8 !important;
    background: #f8fafc !important;
}

.ant-checkbox-checked > .ant-checkbox-inner {
    background: #4166c0 !important;
}

.ant-checkbox-inner:after {
    height: 13px !important;
    width: 0.4rem !important;
}

.filter_checkbox > span {
    color: var(--light-type-high, #070a13) !important;
    font-family: 'DM Sans' !important;
    font-size: 1rem !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 1.5rem !important;
}

.alt_checkbox .ant-checkbox-checked > .ant-checkbox-inner {
    background: #ffc263 !important;
}

.alt_checkbox .ant-checkbox-inner:after {
    box-sizing: border-box !important;
    position: absolute !important;
    top: 50% !important;
    inset-inline-start: 25% !important;
    display: table !important;
    border: 2px solid #121212 !important;
    border-top: 0 !important;
    border-inline-start: 0 !important;
    content: '' !important;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s !important;
}

.events {
    margin: 0;
    padding: 0;
    list-style: none;
}

.events .ant-badge-status {
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.notes-month {
    font-size: 28px;
    text-align: center;
}

.notes-month section {
    font-size: 28px;
}

/* ANTD CALENDAR */

.ant-picker-calendar-header .ant-select {
    width: 120px !important;
}

.ant-picker-calendar {
    padding: 0.75rem !important;
}

.ant-picker-calendar-mode-switch {
    display: none !important;
}

.ant-picker-input > input::placeholder {
    color: var(--light-type-medium, #64748b) !important;
    font-family: 'Public Sans' !important;
    font-size: 1rem !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 1.5rem !important; /* 150% */
}

/* ANTD STEPS */

.ant-steps-item-tail {
    width: 21rem !important;
    height: 0.125rem !important;
    background: #e2e9ee !important;
    margin-inline-start: 0px !important;
    padding: 0px !important;
    z-index: 0 !important;
}

.ant-steps-item-tail::after {
    background-color: #e2e9ee !important;
}

.ant-steps-item-process .ant-steps-item-icon {
    background-color: transparent !important;
    border-color: transparent !important;
}

.ant-steps-item-active .ant-steps-icon {
    display: flex !important;
    width: 2.25rem !important;
    height: 2.25rem !important;
    padding: 0.8125rem !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 0.5rem !important;
    border-radius: 1.125rem !important;
    border: 1px solid var(--Ndovu-Black, #121212) !important;
    background: #ffc263 !important;
    color: var(--Ndovu-Black, #121212) !important;
    font-family: 'DM Sans' !important;
    font-size: 0.875rem !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 150% !important;
}

.ant-steps-item-wait .ant-steps-icon {
    display: flex !important;
    width: 2.25rem !important;
    height: 2.25rem !important;
    padding: 0.8125rem !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 0.5rem !important;
    border-radius: 1.125rem !important;
    border: 1px solid var(--Ndovu-Black, #121212) !important;
    background: #fff !important;
    color: var(--Ndovu-Black, #121212) !important;
    font-family: 'DM Sans' !important;
    font-size: 0.875rem !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 150% !important;
}

.ant-steps-item-finish .ant-steps-icon {
    display: flex !important;
    width: 2.25rem !important;
    height: 2.25rem !important;
    padding: 0.8125rem !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 0.5rem !important;
    border-radius: 1.125rem !important;
    background: #81b480 !important;
}

.ant-steps-finish-icon svg {
    fill: #fff !important;
}

.ant-steps-item-title {
    color: var(--Ndovu-Black, #121212) !important;
    text-align: center !important;
    font-family: 'satoshi_normal' !important;
    font-size: 1rem !important;
    font-style: normal !important;
    /* font-weight: 500 !important; */
    line-height: 1.5rem !important;
}

/* ANTD DRAGGER */

.input-dragger .ant-upload-drag {
    display: flex !important;
    height: 3.125rem !important;
    padding: 0.875rem 1rem !important;
    justify-content: space-between !important;
    align-items: center !important;
    align-self: stretch !important;
    border-radius: 0.375rem !important;
    border: 1px solid var(--Text-Primary, #212121) !important;
    background: transparent !important;
}

.drag_n_drop .ant-upload-drag {
    display: flex !important;
    width: 100% !important;
    height: 16.25rem !important;
    padding: 0.875rem 1rem !important;
    justify-content: space-between !important;
    align-items: center !important;
    border-radius: 0.375rem !important;
    border: 1px solid var(--Tile-stroke, #e2e9ee) !important;
    background: var(--Surface, #f9f9f9) !important;
}

.ant-upload-text {
    color: var(--Text-Primary, #212121);
    font-family: 'satoshi_bold';
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
}

.ant-upload-hint {
    color: var(--Text-Primary, #212121);
    font-family: 'satoshi_medium';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
}

/* ANT PROGRESS */

.ant-progress-bg {
    height: 0.5rem !important;
    flex-shrink: 0;
    background: var(--Ndovu-Black, #121212) !important;
}

.ant-progress-inner {
    background: #ecf2ff !important;
}

/* ANT TOOLTIP */

.ant-tooltip-inner {
    border-radius: 0.5rem !important;
    background: #f6fbff !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1) !important;
    min-width: 34.4375rem !important;
    width: auto !important;
    padding: 0px !important;
}

/* ANT RADIO */

.ant-radio-inner {
    width: 1.5rem !important;
    height: 1.5rem !important;
    border: 2px solid #10101e !important;
}

.ant-radio-checked .ant-radio-inner {
    background-color: #10101e !important;
}

/* ANTD TAGS */

.ant-tag {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 0.5rem !important;
    color: var(--Text-Primary, #212121) !important;
    font-family: 'DM Sans' !important;
    font-size: 0.875rem !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
}

/* ANTD DESCRIPTION */

.ant-descriptions-item-label {
    width: 13rem !important;
    color: var(--Text, #333) !important;
    font-family: 'DM Sans' !important;
    font-size: 1.125rem !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 1.5rem !important;
}

.ant-descriptions-item-content {
    color: #333 !important;
    text-align: center !important;
    font-family: 'DM Sans' !important;
    font-size: 1.125rem !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 1.5rem !important;
}

.ant-descriptions-view > table {
    border: 0px !important;
}

.custom_desc .ant-descriptions-item-label {
    color: var(--Neutral-100, #0a0a0a) !important;
    font-family: 'satoshi_bold' !important;
    font-size: 1rem !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 1.5rem !important; /* 150% */
    width: 100% !important;
}

.custom_desc .ant-descriptions-item {
    padding-bottom: 0.5rem !important;
    color: var(--Neutral-100, #0a0a0a) !important;
    font-family: 'DM Sans' !important;
    font-size: 0.875rem !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 1.25rem !important; /* 142.857% */
}

.custom_desc .ant-descriptions-item-content {
    padding-bottom: 1rem !important;
}

/* ANTD POPOVER */

.ant-popover-inner-content {
    padding: 1rem !important;
}

.ant-popconfirm-title {
    font-weight: 600 !important;
}

.ant-popconfirm-buttons {
    margin-top: 1.25rem !important;
}

/* ANTD DRAWER */

.ant-drawer-header {
    display: none !important;
}

/* ANTD FLOAT BUTTON */
.ant-float-btn-body {
    background-color: #000 !important;
}

.back-to-top {
    position: absolute !important;
    top: -15px !important;
    display: flex !important;
    width: 10.75rem !important;
    height: 10.75rem !important;
    max-width: 86.25rem !important;
    padding: 3.44231rem 0.9255rem 3.55769rem 0.94744rem !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    flex-shrink: 0 !important;
    border-radius: 31.25rem !important;
    background: var(--Main-Light-Gold, #ffcd47) !important;
}

.back-to-top .ant-float-btn-body {
    background-color: transparent !important;
}

.back-to-top .ant-float-btn-content {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.back-to-top .ant-float-btn-icon {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.back-to-top .ant-float-btn-description {
    color: #141711 !important;
    text-align: center !important;
    font-family: 'satoshi_medium' !important;
    font-size: 1.25rem !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 1.75rem !important;
    margin-top: 0.38rem !important;
}

/* ANTD COLLAPSE*/

.ant-collapse {
    background: transparent !important;
    border: none !important;
}

.ant-collapse-header {
    padding: 0.5rem !important;
}

.ant-collapse-content {
    border-top: none !important;
}

.ant-collapse-item {
    border-bottom: none !important;
}

.ant-collapse-header-text {
    color: var(--light-type-high, #070a13) !important;
    font-family: 'Public Sans' !important;
    font-size: 1rem !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 1.5rem !important; /* 150% */
}

.ant-collapse-content-box {
    padding: 0.25rem !important;
}
