@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
    font-family: 'satoshi_lighter';
    src: url('./assets/Fonts/WEB/fonts/Satoshi-Light.woff2') format('woff2'), url('./assets/Fonts/WEB/fonts/Satoshi-Light.woff') format('woff'),
        url('./assets/Fonts/WEB/fonts/Satoshi-Light.ttf') format('truetype');
    font-weight: lighter;
}

@font-face {
    font-family: 'satoshi_normal';
    src: url('./assets/Fonts/WEB/fonts/Satoshi-Regular.woff2') format('woff2'), url('./assets/Fonts/WEB/fonts/Satoshi-Regular.woff') format('woff'),
        url('./assets/Fonts/WEB/fonts/Satoshi-Regular.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'satoshi_medium';
    src: url('./assets/Fonts/WEB/fonts/Satoshi-Medium.woff2') format('woff2'), url('./assets/Fonts/WEB/fonts/Satoshi-Medium.woff') format('woff'),
        url('./assets/Fonts/WEB/fonts/Satoshi-Medium.ttf') format('truetype');
    font-weight: medium;
}

@font-face {
    font-family: 'satoshi_bold';
    src: url('./assets/Fonts/WEB/fonts/Satoshi-Bold.woff2') format('woff2'), url('./assets/Fonts/WEB/fonts/Satoshi-Bold.woff') format('woff'),
        url('./assets/Fonts/WEB/fonts/Satoshi-Bold.ttf') format('truetype');
    font-weight: bold;
}

.ant-input::placeholder {
    color: #626364 !important;
    font-family: 'DM Sans' !important;
    font-size: 1rem !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 1.5rem !important;
}

.input-phone {
    display: flex;
    width: 100%;
    height: 42px;
    align-items: center;
    border-radius: 3px;
    border: 1px solid #9095a0;
    background: var(--White, #fff);
    box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07), 0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}

.input-phone:hover {
    border: 2px solid #388e3c47;
    background: var(--White, #fff);
    box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07), 0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}

.input-phone:focus {
    border: 2px solid #388e3c47;
    background: var(--White, #fff);
    box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07), 0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}

.input {
    max-width: 100%;
    width: 100%;
    height: 3.125rem !important;
    align-items: center;
    padding: 0.875rem 1rem !important;
    border: 1px solid #212121 !important;
    border-radius: 0.375rem !important;
    box-shadow: none !important;
}

.react-tel-input {
    padding: 0px !important;
}

.input:hover {
    border: 2px solid #212121 !important;
}

.input:focus {
    border: 2px solid #212121 !important;
}

.input-borderless {
    max-width: 100%;
    width: 100%;
    height: 100% !important;
    padding: 0.25rem !important;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    background-color: transparent !important;
}

.otp-input {
    display: flex;
    width: 4rem;
    height: 3.5rem;
    padding: 0.875rem 1rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    border: 1px solid var(--Border-Border-Primary, #bdbdbd);
    background: var(--Surface-Primary, #fff);
}

@media (max-width: 600px) {
    .otp-input {
        width: 2.25rem;
        height: 3rem;
        padding: 0.5rem;
        font-size: 0.875rem;
        border-radius: 0.2rem;
    }
}

.text_area {
    display: flex;
    width: 100%;
    min-height: 9.8125rem;
    height: auto;
    padding: 0.875rem 1rem;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.375rem;
    border: 1px solid var(--Text-Primary, #212121);
    box-shadow: none !important;
}

.text_area:hover {
    border: 2px solid #212121 !important;
}

.text_area:focus {
    border: 2px solid #212121 !important;
}

.filter_search {
    display: flex !important;
    padding: 0.625rem 0.75rem !important;
    align-items: center !important;
    gap: 0.5rem !important;
    align-self: stretch !important;
    border-radius: 0.25rem !important;
    background: var(--light-neutrals-200, #f1f5f9) !important;
    height: 2.75rem !important;
    width: 100% !important;
}

.filter_date_piker {
    display: flex !important;
    padding: 0.625rem 0.75rem !important;
    align-items: center !important;
    gap: 0.5rem !important;
    align-self: stretch !important;
    border-radius: 0.25rem !important;
    background: var(--light-neutrals-200, #f1f5f9) !important;
    height: 2.75rem !important;
    width: 100% !important;
}

.cstm-btn {
    width: 100%;
    display: flex;
    padding: 0.75rem 1.5rem;
    gap: 0.25rem;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 1.75rem;
    border: 1px solid #000;
    background: #fff;
    color: var(--Text-Inverse-Primary, #212121);
    font-family: 'DM Sans';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
}

.cstm-btn-2 {
    display: flex;
    width: 100%;
    padding: 0.75rem 1.5rem;
    gap: 0.25rem;
    justify-content: center;
    align-items: center;
    border-radius: 1.75rem;
    background: #000;
    color: var(--Text-Inverse-Primary, #fff);
    font-family: 'Lexend';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
}

.cstm-btn-3 {
    width: 100%;
    display: flex;
    padding: 0.75rem 1.5rem;
    gap: 0.25rem;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 1.75rem;
    border: 1px solid #000;
    background: #ffc263;
    color: var(--Text-Inverse-Primary, #212121);
    font-family: 'DM Sans';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
}

.cstm-btn-3-disabled {
    width: 100%;
    display: flex;
    padding: 0.75rem 1.5rem;
    gap: 0.25rem;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 1.75rem;
    border: 1px solid rgba(0, 0, 0, 0.3);
    background: rgba(255, 194, 99, 0.3);
    color: var(--Text-Inverse-Primary, #212121);
    font-family: 'DM Sans';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
}

.black-round-btn {
    display: flex;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 1.25rem;
    border: 1px solid rgba(127, 86, 217, 0);
    background: #000;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.filter_btn_light {
    width: 100%;
    display: flex;
    height: 2.75rem;
    padding: 0.625rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex: 1 0 0;
    align-self: stretch;
    align-items: center;
    border-radius: 0.5rem;
    border: 1.5px solid var(--light-neutrals-400, #cbd5e1);
    color: var(--light-type-high, #070a13);
    font-family: 'Public Sans';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
}

.filter_btn_dark {
    width: 100%;
    display: flex;
    height: 2.75rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.625rem 1.25rem;
    gap: 0.5rem;
    flex: 1 0 0;
    align-self: stretch;
    background: #10101e;
    border-radius: 0.5rem;
    color: var(--White, #fff);
    font-family: 'Public Sans';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
}

.modal_btn_light {
    width: 100%;
    display: flex;
    padding: 0.375rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.375rem;
    border: 1px solid #000;
    color: var(--Primary-Main, #212143);
    font-family: 'Inter';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.3125rem;
    letter-spacing: -0.00525rem;
}

.modal_btn_dark {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0.375rem 1rem;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.375rem;
    background: #10101e;
    color: var(--Neutral-10, #fff);
    font-family: 'Inter';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.3125rem;
    letter-spacing: -0.00525rem;
}

.decline_btn {
    display: flex;
    width: 100%;
    height: 3.125rem;
    padding: 0.75rem 4.625rem;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    border-radius: 1.75rem;
    border: 1px solid var(--Crimson-red, #c72c3b);
    background: var(--Button-outlined-surface, #fff);
    color: var(--Crimson-red, #c72c3b);
    font-family: 'satoshi_medium';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
}

.action_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: fit-content;
    width: auto;
    height: 3.125rem;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    border-radius: 1.75rem;
    background: var(--Black, #000);
    color: var(--Text-Inverse-Primary, #fff);
    font-family: 'DM Sans';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
}

.register_btn {
    display: inline-flex;
    flex-shrink: 0;
    max-width: 60.375rem;
    padding: 0.3125rem 0.3125rem 0.3125rem 1.25rem;
    align-items: center;
    gap: 0.75rem;
    border-radius: 6.25rem;
    background: #ffcd47;
}

.side-bar-scroll::-webkit-scrollbar-track {
    background-color: transparent;
}

.side-bar-scroll::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

.onboarding {
    background: url('./assets/svg/onboardBg.svg');
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.react-tel-input .form-control {
    height: 100% !important;
    width: 100% !important;
    border: none !important;
    outline: none !important;
    padding-left: 75px !important;
}

.flag-dropdown {
    padding: 10px !important;
}

.card {
    width: 100%;
    border-radius: 14px 14px 0px 0px;
    text-align: center;
    user-select: none;
}

.cover-photo {
    position: relative;
    background: #121212;
    background-size: cover;
    height: 6.4375rem;
    border-radius: 14px 14px 0px 0px;
}

.profile {
    position: absolute;
    width: 100px;
    height: 100px;
    bottom: -40px;
    left: 30%;
    border-radius: 50%;
    padding: 5px;
    z-index: 10000;
    background-color: #fff;
}

.profile-icon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    bottom: -40px;
    left: 30%;
    border-radius: 50%;
    z-index: 10000;
    background-color: #fff;
    font-size: 80px;
    border: 3px solid #e2e9ee;
}

.search {
    width: 100%;
    display: flex;
    height: 3.125rem;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    border-radius: 3.125rem;
    background: #fff;
    box-shadow: 0px 3px 6px 0px rgba(148, 163, 184, 0.15);
    padding: 0.19rem 1.25rem;
}

.search_filter {
    width: 100%;
    display: flex;
    padding: 0.625rem 0.75rem;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
    border-radius: 0.25rem;
    background: var(--light-neutrals-200, #F1F5F9);
}

.register {
    min-height: 100vh;
    height: auto;
    background: url("data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20width='655'%20height='239'%20viewBox='0%200%20655%20239'%20fill='none'%3E%3Cpath%20d='M14.8084%20259.629C-46.9241%20386.319%20113.209%20428.577%20220.105%20435.516C653.11%20437.598%20863.858%20169.314%20799.491%2073.3797C710.591%20-59.1216%20755.847%2019.3798%20687.245%2086.7521C609.649%20142.847%20108.176%20252.032%2014.8084%20259.629Z'%20fill='%23FFB600'%20/%3E%20%3Cpath%20d='M14.8084%20259.629C-46.9241%20386.319%20113.209%20428.577%20220.105%20435.516C653.11%20437.598%20863.858%20169.314%20799.491%2073.3797C710.591%20-59.1216%20755.847%2019.3798%20687.245%2086.7521C609.649%20142.847%20108.176%20252.032%2014.8084%20259.629ZM14.8084%20259.629C31.7957%20240.135%20-47.5257%20255.691%2014.8084%20259.629Z'%20stroke='%23FDC221'%20/%3E%20%3C/svg%3E");
    background-size: 52rem;
    background-repeat: no-repeat;
    background-position: bottom right;
}

.full_page {
    min-height: 100vh;
    height: auto;
}

.filter-panel {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
}

.filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filter-header button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
}

.filter-category {
    margin-bottom: 16px;
}

.filter-category h4 {
    margin-bottom: 8px;
}

.filter-options label {
    display: flex;
    padding: 0rem .5rem;
    margin-bottom: .25rem;
    gap: 0.25rem;
    color: var(--light-type-high, #070A13);
    font-family: "Public Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.filter-footer {
    display: flex;
    justify-content: space-between;
}


.filter-footer button:first-child {
    display: flex;
    padding: 0.625rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 0.5rem;
    border: 1.5px solid var(--light-neutrals-400, #CBD5E1);
    color: var(--light-type-high, #070A13);
    font-family: "Public Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
}

.filter-footer button:last-child {
    display: flex;
    padding: 0.625rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 0.5rem;
    background: #10101E;
    color: var(--White, #FFF);
    font-family: "Public Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
}