@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

:root {
    /* Primary Colors */
    /* --primary-navy-blue: #1E3A8A; */
    --primary-navy-blue: #3f51f4;
    --primary-emerald-green: #2f855a;
    --primary-slate-grey: #4a5568;

    /* Secondary Colors */
    --secondary-gold: #d69e2e;
    --secondary-light-grey: #a0aec0;
    --soft-grey: #e0e0e0;

    /* Neutral Colors */
    --neutral-white: #f9f9f9;

    /* Accent Colors */
    --accent-royal-blue: #3b82f6;
    --accent-soft-green: #68d391;
    --accent-warm-yellow: #f6e05e;
}

body {
    background-color: var(--neutral-white);
    color: var(--primary-slate-grey);
}

sup {
    vertical-align: super !important;
}

table {
    border: 1px solid var(--Surface-Table-header-blue, #f2f4f7) !important;
}

.button-primary {
    background-color: var(--accent-royal-blue);
    color: var(--neutral-white);
}

.button-secondary {
    background-color: var(--secondary-gold);
    color: var(--neutral-white);
}

.success-message {
    color: var(--accent-soft-green);
}

.warning-message {
    color: var(--accent-warm-yellow);
}

.white_card {
    display: flex;
    padding: 1rem 2.5rem;
    align-self: stretch;
    border-radius: 1.25rem;
    background: #fff;
}

@media (max-width: 600px) {
    .white_card {
        padding: 0.5rem;
    }
}

.account_card {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    width: 100%;
    min-height: 5.3125rem;
    height: auto;
    padding: 1.25rem 1.5rem;
    align-items: flex-start;
    border-radius: 0.75rem;
    border: 1px solid var(--Ndovu-Black, #121212);
    background: #fff;
}

.account_card_alt {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    width: 100%;
    min-height: 5.3125rem;
    height: auto;
    padding: 1.25rem 1.5rem;
    align-items: flex-start;
    border-radius: 1.5rem;
    border: 1px solid var(--Tile-stroke, #e2e9ee);
    background: #fff;
}

.modal_option_card {
    display: flex;
    min-width: 16.125rem;
    width: auto;
    padding: 3.75rem 5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    border-radius: 0.5rem;
    border: 1px solid var(--Text-Inverse-Tertiary, #f5f5f5);
    background: #fff;
}

.modal_option_card:hover {
    border-radius: 0.5rem;
    border: 1.5px solid #ffca76;
    background: #fffdf9;
}

.modal_option_card_inner {
    width: fit-content;
    display: flex;
    padding: 1.25rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    border-radius: 62.5rem;
    background: #fff8ec;
}

.modal_option_card:hover > .modal_option_card_inner {
    background: #ffe8c4;
}

.pointer {
    cursor: pointer;
    width: 100%;
}

.status_icon_text {
    color: var(--Text-Primary, #212121);
    font-family: 'DM Sans';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.custom-tooltip {
    display: flex;
    flex-direction: column;
    width: auto;
    background-color: #fff;
    border: 1px solid #4a5568;
    padding: 0.5rem;
}

.get_started_list {
    counter-reset: custom-counter;
    list-style: none;
}

.get_started_list li {
    counter-increment: custom-counter;
    margin-bottom: 1.22rem;
    position: relative;
    padding-left: 2rem;
}

.get_started_list li::before {
    content: counter(custom-counter, decimal-leading-zero) '.';
    position: absolute;
    left: 0;
    top: 0;
    color: #e07c5c;
    font-size: 1.2rem;
    font-weight: bold;
}

.get_started_list strong {
    display: block;
    font-size: 1rem;
    color: #000;
}

.reach_out_card {
    display: flex;
    width: 20.125rem;
    height: 14.375rem;
    padding: 1.875rem;
    flex-direction: column;
    align-items: center;
    gap: 0.9375rem;
    flex-shrink: 0;
    border-radius: 0.625rem;
    background: #fff;
}

/* .feature-card {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  } */

.feature-image {
    background-size: contain;
    background-repeat: no-repeat;
}

.upload_avatar {
    width: fit-content;
    display: flex;
    height: 3.125rem;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    border-radius: 1.75rem;
    background: var(--Black, #000);
    cursor: pointer;
    color: var(--Text-Inverse-Primary, #fff);
    font-family: 'DM Sans';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 150% */
}
